
/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */

// Vue.component('example', require('./components/Example.vue'));

// const app = new Vue({
//     el: '#app'
// });

$('.page_2 a.open').click(function (e) {
    e.preventDefault();
    $('.doc').attr('src', $(this).attr('href'));
});

//document.addEventListener('contextmenu', event => event.preventDefault());
document.oncontextmenu = document.body.oncontextmenu = function() {return false;}


$(document).ready(function(){
    setTimeout(function(){
        $('.doc_load').remove();
        $('.open-doc').removeClass('hidden');
    }, 25000);
});